import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./Components/Home/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Slide/Home";
import About from "./Components/About/About";
import Productli from "./Components/Product/Productli";
import Contact from "./Components/Contact/Contact";
import Gallery from "./Components/Gallery/Gallery";
import Career from "./Components/Career/Career";
import Antidepressant from "./Components/Product/Antidepressant";
import Antipsychotic from "./Components/Product/Antipsychotic";
import Antyanxiety from "./Components/Product/Antianxiety";
import Ventigo from "./Components/Product/Ventigo";
import Pain from "./Components/Product/Pain";
import Antispastic from "./Components/Product/Antispastic";
import Ppi from "./Components/Product/Ppi";
import Cvseg from "./Components/Product/Cvseg";
import Nutraceutical from "./Components/Product/Nutraceutical";
function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Switch>
        <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/product" component={Productli} />
          <Route path="/contact" component={Contact} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/career" component={Career} />
          <Route path="/antidepressant" component={Antidepressant} />
          <Route path="/antipsychotic" component={Antipsychotic}/>
          <Route path= "/antyanxiety" component={Antyanxiety}/>
          <Route path= "/ventigo" component={Ventigo}/>
          <Route path= "/pain" component={Pain}/>
          <Route path= "/antispastic" component={Antispastic}/>
          <Route path= "/ppi" component={Ppi}/>
          <Route path= "/cvseg" component={Cvseg}/>
          <Route path= "/nutraceutical" component={Nutraceutical}/>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;