import React from "react";
import img1 from "./Images/1.jpeg";
import img2 from "./Images/2.jpeg";

import img5 from "./Images/5.jpeg";
import img6 from "./Images/6.jpeg";
import img7 from "./Images/7.jpeg";
import img8 from "./Images/8.jpeg";

import img10 from "./Images/10.jpeg";
import img11 from "./Images/11.jpeg";
import img12 from "./Images/12.jpeg";
import img13 from "./Images/13.jpeg";
import img14 from "./Images/14.jpeg";
import img15 from "./Images/15.jpeg";
import img16 from "./Images/16.jpeg";

import img18 from "./Images/18.jpeg";
import img19 from "./Images/19.jpeg";
import img20 from "./Images/20.jpeg";
import img21 from "./Images/21.jpeg";
import img22 from "./Images/22.jpeg";


import img23 from "./Images/23.jpeg";
import img24 from "./Images/24.jpeg";
import img25 from "./Images/25.jpeg";
import img26 from "./Images/26.jpeg";
import img27 from "./Images/27.jpeg";
import img28 from "./Images/28.jpeg";
import img29 from "./Images/29.jpeg";
import img30 from "./Images/30.jpeg";
import img31 from "./Images/31.jpeg";





import "./Gallery.css";
const Gallery = () => {
  return (
    <div>
      <div className="gallery">
        <h1>Gallery</h1>
      </div>

      <div className="main-gallery ">
        <h1>Budget Meet 2023-24</h1>
        <div className="list-photos">
          <img src={img1} alt="img" />
          <img src={img2} alt="img" />
        </div>
        <div className="list-photos">
          <img src={img5} alt="img" />
          <img src={img6} alt="img" />
        </div>

        <div className="list-photos">
          <img src={img7} alt="img" />
          <img src={img8} alt="img" />
        </div>

        <div className="list-photos">
          <img src={img10} alt="img" />
          <img src={img11} alt="img" />
        </div>
      </div>

      {/* Cycle Meeting*/}
      <div className="main-gallery five ">
      <h1>Cycle Meeting</h1>
      <div className="cycle">
        <div className="list-photos cycle">
          <img src={img12} alt="img" />
          <img src={img14} alt="img" />
          <img src={img13} alt="img" />
          <img src={img15} alt="img" />
        </div>

        <div className="list-photos">
        <img src={img16} alt="img" />
         
          <img src={img18} alt="img" />
          <img src={img19} alt="img" />
          </div>

          <div className="list-photos">
          <img src={img20} alt="img" />
          <img src={img21} alt="img" />
          <img src={img22} alt="img" />
          </div>
          </div>
          
        </div>


        {/*Budget meet 2022-23*/}

        <div className="main-gallery  ">
      <h1>Budget Meet 2022-23</h1>
      <div className="Budget">
        <div className="list-photos ">
          <img src={img23} alt="img" />
          <img src={img24} alt="img" />
          <img src={img25} alt="img" />
          <img src={img26} alt="img" />
        </div>

        <div className="list-photos">
        <img src={img27} alt="img" />
          <img src={img28} alt="img" />
          <img src={img29} alt="img" />
          <img src={img30} alt="img" />
          <img src={img31} alt="img" />
          </div>

          
          </div>
          
        </div>

  
    </div>
  );
};

export default Gallery;
