import React from "react";

const Career = () => {
  return (
<div className="career-main">
<h1>Hello</h1>
</div>
  );
};

export default Career;