import React from "react";
import Slider from "react-slick";
import Sl from "../../Images/i1.jpg";
import Sli from "../../Images/i2.jpg";
import Slid from "../../Images/i3.jpg";
import mainImg from "../../Images/blob 1.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Slide.css";
import fig1 from "./images/fig1.jpeg";
import fig2 from "./images/fig2.jpeg";
import fig3 from "./images/fig3.jpeg";
import fig4 from "./images/fig4.jpeg";
import fig5 from "./images/fig5.jpeg";
import fig6 from "./images/fig6.jpeg";

import puzz from "./images/puzz.png";
import mission from "./images/Mission.png";
import quality from "./images/quality.png";
const SliderI = () => { 


  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="main-slider">
          <img className="slider-image" src={Sl} alt="Slide 1" />
          <div className="img-txt">
            <h2>Welcome To</h2>
            <h1>Samproj Pharma</h1>
            <h3 >A healthcare Companion, You can Trust.</h3>
          </div>
        </div>
        <div>
          <img className="slider-image" src={Sli} alt="Slide 2" />
        </div>
        <div>
          <img className="slider-image" src={Slid} alt="Slide 3" />
        </div>
      </Slider>
      <div className="main-heading">
        <h1>Welcome to Samproj</h1>
      </div>
      <div className="container">
        <div className="main-cont">
          <div className="main-p">
            <p>
            At Samproj Pharmaceuticals, we are dedicated to improving the health and well-being of people around the world. With a strong commitment to research, innovation, and quality, we strive to deliver pharmaceutical solutions that make a difference in patients' lives.
            </p>

            <p>
            Samproj Pharma is one of the fastest growing Pharma company which is Majorly dealing in Neuropsychiatric segment & CV segment & currently having its Operations in 5 State.
            </p>
          </div>
          <div className="main-img-h">
            <img src={mainImg} alt="Mainimg" />
          </div>
        </div>
      </div>
      <div className="main-about">
        <div className="about-txt">
          <h1>About</h1>
        </div>
        <div className="main-pt">
          <p>
          Samproj is a new age dynamic company established in the year 2020 in Pune. As a corporate, we believe in providing an innovative solution to healthcare problems and possess an entrepreneurial culture to build company and products for the same. We are committed to continuously upgrade its knowledge, expertise, and technology for the cause of health.
          We are offering world class quality pharmaceutical products and services especially for chronic disease management
          </p>
          <div className="about-boxses">
          <div className="box one">
                <div className="box-heading">
                    <div className="box-img">
                      <img src={puzz} alt="puzz" />
                   </div>
                     <div className="head-b">
                      <h1>Our Expertise</h1>
                    </div>
                </div>
              <p className="paragraph">With a team of experienced scientists, researchers, and healthcare professionals, we specialize in developing pharmaceutical products in various therapeutic areas. From cardiovascular health to respiratory care, from neurology to oncology, we focus on addressing critical medical challenges and delivering breakthrough treatments.</p>
          </div>
            <div className="box two">
            <div className="box-heading">
            <div className="box-img">
                      <img src={mission} alt="mission" />
                   </div>
                   <div className="head-b">
              <h1>Our Mission</h1>
              </div>
              </div>
              <p className="paragraph">Our mission is to develop and provide high-quality, safe, and effective medications that meet the diverse healthcare needs of individuals across all age groups. We aim to contribute to the advancement of healthcare through continuous research and development of innovative therapies.</p>
            </div>
            <div className="box three">
            <div className="box-heading">
            <div className="box-img">
                      <img src={quality} alt="" />
                   </div>
                   <div className="head-b">
              <h1>Quality Assurance</h1>
              </div>
              </div>
              <p className="paragraph">At Samproj Pharmaceuticals, quality is our top priority. We adhere to strict international standards and regulatory guidelines to ensure the safety, efficacy, and reliability of our products. Our state-of-the-art manufacturing facilities and rigorous quality control processes ensure that every product leaving our facilities is of the highest standard.</p>
            </div>
          </div>
        </div>
      </div>


 {/* Testimonials */}
 <div className="slider-container-one">
 <div className="testimonial-slider">
        <Slider {...settings}>
              <div className="testimonial">
                  <img src={fig3} alt="Testimonial 1" />
                     <h2>Prafulla Somani</h2>
                        <h3>Director</h3>
                         <p> "I am incredibly impressed with the services provided by Samproj Pharmaceuticals. As the director of our organization, I have witnessed firsthand their commitment to excellence and innovation. Their dedication to delivering high-quality pharmaceutical products has played a pivotal role in our company's success. I am confident in their capabilities and grateful for their partnership in helping us improve the health and well-being of our customers." </p>
              </div>


              <div className="testimonial">
                  <img src={fig1} alt="Testimonial 2" />
                    <h2>Santosh Mishra</h2>
                     <h3>Sales Manager</h3>
                      <p> "We have been working with Samproj Pharmaceuticals for several years and have found their team to be highly professional and dedicated. Their state-of-the-art facilities and stringent quality control processes ensure that we receive top-notch pharmaceutical products every time." </p>
              </div>


              <div className="testimonial">
                  <img src={fig2} alt="Testimonial 3" />
                    <h2>Sameer Bajare</h2>
                      <h3>Sales Manager</h3>
                       <p> "As a valued partner, Samproj Pharmaceuticals has exceeded our expectations with their exceptional manufacturing capabilities and attention to detail. Their commitment to quality and timely delivery has been instrumental in our success.".</p>
              </div>


              <div className="testimonial">
                  <img src={fig4} alt="Testimonial 4" />
                    <h2>Yogendra Dhole</h2>
                      <h3>Sales Manager</h3>
                        <p> "As Team we are growing and  this is creating future for Many opportunities. Great teamwork is the only way we create the breakthroughs for self and each team member Standing Strong and Tall is my key for success."</p>
              </div>

              <div className="testimonial">
                  <img src={fig5} alt="Testimonial 4" />
                    <h2>J B dafedar</h2>
                      <h3>Sales Manager</h3>
                        <p> "United in spirit, we achieve remarkable milestones together, opening doors to countless opportunities. Our camaraderie empowers us to overcome challenges, seeing each team member flourish. Standing strong together drives our shared success—a remarkable journey!"</p>
              </div>

              <div className="testimonial">
                  <img src={fig6} alt="Testimonial 4" />
                    <h2>Amol choudhari</h2>
                      <h3>Sales Manager</h3>
                        <p> "Life-changing teamwork! Trust and encouragement foster individual and collective growth, unveiling exciting prospects ahead. Our united efforts and unique strengths shape our success—transformative and exhilarating!"</p>
              </div>

        </Slider>
      </div>
      </div>
      {/* Testimonials-End */}

      
    </div>
  );
};

const SamplePrevArrow = ({ onClick }) => (
  <button className="slider-arrow prev-arrow" onClick={onClick}>
    &lt;
  </button>
);

const SampleNextArrow = ({ onClick }) => (
  <button className="slider-arrow next-arrow" onClick={onClick}>
    &gt;
  </button>
);

export default SliderI;
