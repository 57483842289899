import React, { useState } from "react";
import loraprojmd from "./Product-Images/loraprojmd.jpg";
import loraprojmd2 from "./Product-Images/loraprojmd2.jpg";

import "./Product.css";

const Antyanxiety = () => {
    const [loraprojmdLightboxOpen, setloraprojmdLightboxOpen] = useState(false);
    const [loraprojmd2LightboxOpen, setloraprojmd2LightboxOpen] =useState(false);



    const openloraprojmdLightbox = () => {
        setloraprojmdLightboxOpen(true);
      };

    const openloraprojmd2Lightbox = () =>{
      setloraprojmd2LightboxOpen(true);
    }


      const closeLightboxes = () => {
        setloraprojmdLightboxOpen(false);
        setloraprojmd2LightboxOpen(false);
      };




      
  return (
    <div className="site-wrapper">
      <div className="container-main">
      <h1>Anxiolytic</h1>
      </div>

      <div className="services-w3ls">
      <div className="container" style={{ display: "flex" }}>

      <div className="main-products" style={{ marginTop: 50 }}>
            <h1 style={{ textAlign: "center", marginBottom: 30 }}>
              <b>Loraproj MD 0.5</b>
            </h1>
            <div className="products-li">
              <div className="lightbox-container">
                {loraprojmdLightboxOpen && (
                  <div className="lightbox-overlay" onClick={closeLightboxes} />
                )}
                <img
                  src={loraprojmd}
                  style={{ maxWidth: 400, cursor: "pointer" }}
                  alt=""
                  onClick={openloraprojmdLightbox}
                />
              </div>
              {loraprojmdLightboxOpen && (
                <div className="lightbox-content">
                  <img src={loraprojmd} className="lightbox-image" alt="" />
                </div>
              )}
            </div>
          </div>

          <div className="main-products" style={{ marginTop: 50 }}>
          <h1 style={{ textAlign: "center", marginBottom: 30 }}>
            <b>Loraproj MD 2</b>
          </h1>
          <div className="products-li">
            <div className="lightbox-container">
              {loraprojmd2LightboxOpen && (
                <div className="lightbox-overlay" onClick={closeLightboxes} />
              )}
              <img
                src={loraprojmd2}
                style={{ maxWidth: 400, cursor: "pointer" }}
                alt=""
                onClick={openloraprojmd2Lightbox}
              />
            </div>
            {loraprojmd2LightboxOpen && (
              <div className="lightbox-content">
                <img src={loraprojmd2} className="lightbox-image" alt="" />
              </div>
            )}
          </div>
        </div>





          </div>
          </div>
      
      
     
    </div>
  );
};
export default Antyanxiety;
