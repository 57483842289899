import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Logo from "../../Images/Logo.png";

const Navbar = () => {
  console.log("Suraj Patil");
  return (
    <div>
      <nav className="navbar">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <input type="checkbox" id="menu-toggle" className="menu-toggle" />
        <label htmlFor="menu-toggle" className="menu-icon">
          <span className="menu-icon__line"></span>
          <span className="menu-icon__line"></span>
          <span className="menu-icon__line"></span>
        </label>
        <ul className="menu">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <div className="dropdown">
            <li>
              <Link to="/product">Product List</Link>
            </li>
            <div className="dropdown-content">
              <li>
                <Link to="/antidepressant">Antidepressant</Link>
              </li>
              <li>
              <Link to="/nutraceutical">Nutraceutical</Link>
              </li>
              <li>
                <Link to="/antipsychotic">Antipsychotic / Mood stabilizer</Link>
              </li>
              <li>
                <Link to="/antyanxiety">Anxiolytic</Link>
              </li>
              <li>
                <Link to="/ventigo">Vertigo</Link>
              </li>
              <li>
                <Link to="/pain">Pain-Management</Link>
              </li>
              <li>
                <Link to="/antispastic">Anti-Spastic</Link>
              </li>
              <li>
                <Link to="/ppi">PPI</Link>
              </li>
              <li>
                <Link to="/cvseg">CV Segment</Link>
              </li>
            </div>
          </div>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
