import React from "react";
import "./Product.css";
// import Ai from "../Images/i4.jpg";
const Product = () => {


    return (
        <div>
            <div className="main-Product">
            <div className="prod-head">
            <h1>Product List</h1>
            </div>
            </div>

                    <div class="line text-center">
                         <div class="margin">
                                <table width="80%" border="1" align="center" cellpadding="5" cellspacing="4" 
                                    bordercolor="#d5d5d5" bgcolor="#e8e8e8" class="responsive">
                                    <tbody>
                                        <tr>
                                                <td valign="top" width="5%">
                                                    <p align="center"><strong>SR</strong></p> </td>			
                                                
                                                <td valign="top" width="18%">
                                                    <p>	<strong>Product Name</strong></p> </td>			
                                                
                                                <td valign="top" width="40%">
                                                    <p>	<strong>Composition</strong></p> </td>
                                                
                                                <td colspan="2" valign="top" width="10%">
                                                    <p>	<strong>Pack</strong></p> </td>
                                                
                                                <td colspan="2" valign="top" width="10%">
                                                    <p>	<strong>Product Image</strong></p> </td>		
                                        </tr>	
                                
                                            <tr >
                                                <td valign="middle" width="5%"><p>1</p> </td>			
                                                <td valign="middle" width="18%" ><p><strong>Olasam-MD</strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Olanzapine MD 1.25/2.5/5/10 mg Tablets</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>
                                                {/*Product-Image*/}
                                                </p> 
                                                </td>
                                            </tr>	
                            
                            


                                            <tr>
                                                <td valign="middle" width="5%">
                                                    <p>2</p> </td>			
                                                <td valign="middle" width="18%"><p><strong>Cilasam</strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Escitalopram 5/10/15/20 mg</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>		</p> 
                                                </td>	
                                            </tr>	
                            


                                            <tr>
                                                <td valign="middle" width="5%">
                                                    <p>3</p> </td>			
                                                <td valign="middle" width="18%"><p><strong>Cilasam-Plus 5   </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Escitalopram 5 mg + Clonazepam 0.5</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>		</p> 
                                                </td>	
                                            </tr>


                                            <tr>
                                                <td valign="middle" width="5%">
                                                    <p>4</p> </td>			
                                                <td valign="middle" width="18%"><p><strong>Cilasam-Plus   </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Escitalopram 10 mg + Clonazepam 0.5</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>		</p> 
                                                </td>	
                                            </tr>




                                            <tr>
                                                <td valign="middle" width="5%">
                                                    <p>5</p> </td>			
                                                <td valign="middle" width="18%"><p><strong>Loraproj-MD </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Lorazepam Mouth Dissolving Tablets 0.5/1/2</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>		</p> 
                                                </td>	
                                            </tr>


                                            <tr>
                                                <td valign="middle" width="5%">
                                                    <p>6</p> </td>			
                                                <td valign="middle" width="18%"><p><strong>Cilasam-H</strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Escitalopram 5 mg + Clonazepam 0.25</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>		</p> 
                                                </td>	
                                            </tr>

                            


                                            <tr>
                                                <td valign="middle" width="5%">
                                                    <p>7</p> </td>			
                                                <td valign="middle" width="18%"><p><strong>Cilasam-LS</strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Escitalopram 10 mg + Clonazepam 0.25</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>		</p> 
                                                </td>	
                                            </tr>





                                            <tr>
                                                <td valign="middle" width="5%"><p>8</p> </td>			
                                                <td valign="middle" width="18%"><p><strong>Prozapine</strong></p> </td>	
                                                <td valign="middle" width="40%"><p> Clozapine 25/50/100/200 mg   </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>
                            
                            
                            
                                            <tr>
                                                <td valign="middle" width="5%"><p>9</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Arisam  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p> Aripiprazole 2/5/10/15 mg  </p></td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p></td>	
                                                <td colspan="2" valign="middle" width="10%"><p>		</p> 
                                                </td>		
                                            </tr>



                                            <tr>
                                                <td valign="middle" width="5%"><p>10</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Paritin-CR  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>   Paroxetine CR 12.5/25 mg</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p> </td>		
                                                <td colspan="2" valign="middle" width="10%"><p>    </p> 
                                                </td>		
                                            </tr>
                            

                                            <tr>
                                                <td valign="middle" width="5%"><p>11</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Liosam-10  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Baclofen 10 mg Tablets</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p> </td>		
                                                <td colspan="2" valign="middle" width="10%"><p>    </p> 
                                                </td>		
                                            </tr>


                                            <tr>
                                                <td valign="middle" width="5%"><p>12</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Liosam-20/30 ER  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Baclofen (GRS) 20/30 mg ER Tablets</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p> </td>		
                                                <td colspan="2" valign="middle" width="10%"><p>    </p> 
                                                </td>		
                                            </tr>


                                            <tr>
                                                <td valign="middle" width="5%"><p>13</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Bestivert  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Betahistine 8/16/OD 24 mg Tablets</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p> </td>		
                                                <td colspan="2" valign="middle" width="10%"><p>    </p> 
                                                </td>		
                                            </tr>




                                            <tr>
                                                <td valign="middle" width="5%"><p>14</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Paritin-Plus 12.5  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>   Paroxetine CR 12.5 + Clonazepam 0.5 mg</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p> </td>		
                                                <td colspan="2" valign="middle" width="10%"><p>    </p> 
                                                </td>		
                                            </tr>



                                            <tr>
                                                <td valign="middle" width="5%"><p>15</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Rozaproj-A 75/150  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Rosuvastatin 10 mg + Asprin 75/150 mg Gastro Resistant Capsules</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Capsules</p> </td>		
                                                <td colspan="2" valign="middle" width="10%"><p>    </p> 
                                                </td>		
                                            </tr>


                                            <tr>
                                                <td valign="middle" width="5%"><p>16</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Rozaproj-Gold</strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Rosuvastatin 10 mg + Clopidogrel 75 mg + Asprin 75 mg Capsules</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Capsules</p> </td>		
                                                <td colspan="2" valign="middle" width="10%"><p>    </p> 
                                                </td>		
                                            </tr>
                            
                            
                                            <tr>
                                                <td valign="middle" width="5%"><p>17</p> </td>			
                                                <td valign="middle" width="18%"><p><strong>  Benfosam </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Benfotiamine 150 mg + Mecobalamin 150 mcg + Calcium Carbonate 500 mg + Folic Acid 1.5 mg + Ala 100 mg + Vitamin D3 1000IU + Chromium-Polynicotinate 200 mcg + Pyridoxine HCL 3 mg + Insositol 100 mg  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>	
                                            </tr>




                                            <tr>
                                                <td valign="middle" width="5%"><p>18</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Venmax  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p> Desvenlafaxine ER 25/50/100 mg Tablets </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>
                            
                            
                            
                                            <tr>
                                                <td valign="middle" width="5%"><p>19</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Proj 40  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p> Pantoprazole 40 mg   </p></td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets</p> </td>	
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>			
                                            </tr>





                                            <tr>
                                                <td valign="middle" width="5%"><p>20</p> </td>			
                                                <td valign="middle" width="18%"><p><strong>Amiproj   </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>  Amisulpride 50/100/SR 200 mg   </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>
                                            </tr>
                            
                            
                            
                                            <tr>
                                                <td valign="middle" width="5%"><p>21</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Risproj 0.5/1/2  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>  Risperidone MD 0.5/1/2 mg  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>


                                            <tr>
                                                <td valign="middle" width="5%"><p>22</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Risproj-LS  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>  Risperidone 2 mg + Trihexyphenidyl 2 mg MD  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>


                                            <tr>
                                                <td valign="middle" width="5%"><p>23</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Risproj-Plus  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>  Risperidone 3 mg + Trihexyphenidyl 2 mg MD  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>

                                            <tr>
                                                <td valign="middle" width="5%"><p>24</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Risproj-Forte </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>  Risperidone 4 mg + Trihexyphenidyl 2 mg MD  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                           </tr>


                                            <tr>
                                                <td valign="middle" width="5%"><p>25</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Diproj ER  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>  Divalproex Sodium(ER) 250/500  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>

                                            <tr>
                                                <td valign="middle" width="5%"><p>26</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Duloxin  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>  Duloxetine 20/30 mg  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                           </tr>
                                


                                            <tr>
                                                <td valign="middle" width="5%"><p>27</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Ziproj  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Ziprasidone 20 / 40 mg  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>



                                            <tr>
                                                <td valign="middle" width="5%"><p>28</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Benfosam-LS  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Methylcobalamin 1500 mcg + Alpha Lipoic Acid 100 mg + Vitamin D3 1000 IU + Pyridoxine HCL 3 mg + Folic Acid 1.5 mg  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>




                                            <tr>
                                                <td valign="middle" width="5%"><p>29</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Benfosam-Inj  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>(For IV/IM use only) Thiamine HCI 100 mg + Methylcobalamin 1000 mcg + Pyridoxine HCI 100 mg + Niacinamide 100 mg in 2 ml  </p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>





                                            <tr>
                                                <td valign="middle" width="5%"><p>30</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Tryptosam-10/25  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Amitriptyline 10/25 mg</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>


                                            <tr>
                                                <td valign="middle" width="5%"><p>31</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Tryptosam-CZ  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Amitriptyline 25 mg + Chlordiazepoxide 10 mg</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr> 


                                            <tr>
                                                <td valign="middle" width="5%"><p>32</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Tryptosam-CZH  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Amitriptyline 12.5 mg + Chlordiazepoxide 5 mg</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr> 


                                            <tr>
                                                <td valign="middle" width="5%"><p>33</p> </td>			
                                                <td valign="middle" width="18%"><p><strong> Clomiproj  </strong></p> </td>	
                                                <td valign="middle" width="40%"><p>Clomipramine HCL Tablets 25 mg / 50 mg / SR 75 mg.</p> </td>
                                                <td colspan="2" valign="middle" width="10%"><p>Tablets	</p> </td>			
                                                <td colspan="2" valign="middle" width="10%"><p>   </p> 
                                                </td>		
                                            </tr>


                                    </tbody>
                                </table>

            
                        </div>
                    </div>
            
        </div>

    );
}
export default Product;