import React, { useState } from "react";
import proj40 from "./Product-Images/proj40.jpg";

import "./Product.css";

const Ppi = () => {
    const [proj40LightboxOpen, setproj40LightboxOpen] = useState(false);
  
    


    const openproj40Lightbox = () => {
        setproj40LightboxOpen(true);
      };

  

      


    const closeLightboxes = () => {
        setproj40LightboxOpen(false);
       

    };











  return (
    <div className="site-wrapper">
          <div className="container-main">
            <h1>PPI</h1>
          </div>
          



                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                        <b>Proj-40</b>
                        </h1>
                        <div className="products-li">
                        <div className="lightbox-container">
                            {proj40LightboxOpen && (
                            <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                            src={proj40}
                            style={{ maxWidth: 400, cursor: "pointer" }}
                            alt=""
                            onClick={openproj40Lightbox}
                            />
                        </div>
                        {proj40LightboxOpen && (
                            <div className="lightbox-content">
                            <img src={proj40} className="lightbox-image" alt="" />
                            </div>
                        )}
                        </div>
                    </div>
          


                   

















          




    </div>
    
    
  );
};
export default Ppi;
