import React, { useState } from "react";
import "./Contact.css";
//import Email from "./Images/email.png";


const Contact = () => {
    const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log("Form submitted:", email, phoneNumber, message);
  };
  return (
    <div>
      <div className="contact-bg">
        <div className="cont-txt">
          <h1>Contact Us</h1>
          </div>
  
        
      </div>


  {/*Form*/}

<div className="main-cont-for">

      <div className="form-container">
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>

        <button type="submit">Submit</button>
      </form>
      
    </div>
    <div className="cont-address">
      <div className="main-address">
      <div className="address c">
       <h2>Address:-</h2>
       <p>101, Avalon, Suncity Road, Sinhagad Road, Pune Maharashtra - 411051</p>
      </div>
      <div className="email c">
      <h2>Email</h2>
      <p >Info@samprojpharma.com</p>
      </div>
      <div className="phone c">
      <h2>Phone No:- +919890096181</h2>
      </div>
      </div>
      </div>
      </div>

{/*Form-End*/}



    </div>
  );
};
export default Contact;
