import React, { useState } from "react";
import benfosam from "./Product-Images/benfosamt.jpg";
import benfosamls from "./Product-Images/benfosamls.jpg";
import "./Product.css";

const Nutraceutical = () => {
    const [benfosamLightboxOpen, setbenfosamLightboxOpen] =useState(false);
    const [benfosamlsLightboxOpen, setbenfosamlsLightboxOpen] =useState(false);
    

    const openbenfosamLightbox =() =>{
        setbenfosamLightboxOpen(true);
    };
    const openbenfosamlsLightbox =() =>{
        setbenfosamlsLightboxOpen(true);
    };


    const closeLightboxes = () => {
        setbenfosamLightboxOpen(false);
        setbenfosamlsLightboxOpen(false);
      
     
    };









  return (
    <div className="site-wrapper">
          <div className="container-main">
            <h1>Nutraceutical</h1>
          </div>
         


             <div className="services-w3ls">
             <div className="container" style={{ display: "flex" }}>
                           <div className="main-products" style={{ marginTop: 50 }}>
                           <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                           <b>Benfosam</b>
                           </h1>
                           <div className="products-li">
                           <div className="lightbox-container">
                               {benfosamLightboxOpen && (
                               <div className="lightbox-overlay" onClick={closeLightboxes} />
                               )}
                               <img
                               src={benfosam}
                               style={{ maxWidth: 400, cursor: "pointer" }}
                               alt=""
                               onClick={openbenfosamLightbox}
                               />
                           </div>
                           {benfosamLightboxOpen && (
                               <div className="lightbox-content">
                               <img src={benfosam} className="lightbox-image" alt="" />
                               </div>
                           )}
                           </div>
                       </div>
               
   
   
                       <div className="main-products" style={{ marginTop: 50 }}>
                           <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                           <b>Benfosam-ls</b>
                           </h1>
                           <div className="products-li">
                           <div className="lightbox-container">
                               {benfosamlsLightboxOpen && (
                               <div className="lightbox-overlay" onClick={closeLightboxes} />
                               )}
                               <img
                               src={benfosamls}
                               style={{ maxWidth: 400, cursor: "pointer" }}
                               alt=""
                               onClick={openbenfosamlsLightbox}
                               />
                           </div>
                           {benfosamlsLightboxOpen && (
                               <div className="lightbox-content">
                               <img src={benfosamls} className="lightbox-image" alt="" />
                               </div>
                           )}
                           </div>
                       </div>
             
             
             
             
             </div>
             </div>





          
             </div>
        




 
    
    
  );
};
export default Nutraceutical;
