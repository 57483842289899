import React, { useState } from "react";
import bestivert8 from "./Product-Images/bestivert8.jpg";
import bestivert16 from "./Product-Images/bestivert16.jpg";
import bestivert24 from "./Product-Images/bestivert24.jpg";
import "./Product.css";

const Ventigo = () => {
    const [bestivert8LightboxOpen, setbestivert8LightboxOpen] = useState(false);
    const [bestivert16LightboxOpen, setbestivert16LightboxOpen] =useState(false);
    const [bestivert24LightboxOpen, setbestivert24LightboxOpen] =useState(false);
    


    const openbestivert8Lightbox = () => {
        setbestivert8LightboxOpen(true);
      };

    const openbestivert16Lightbox =() =>{
        setbestivert16LightboxOpen(true);
    };

    const openbestivert24Lightbox = () =>{
        setbestivert24LightboxOpen(true);
    };


      


    const closeLightboxes = () => {
        setbestivert8LightboxOpen(false);
        setbestivert16LightboxOpen(false);
        setbestivert24LightboxOpen(false);

    };











  return (
    <div className="site-wrapper">
          <div className="container-main">
            <h1>Vertigo</h1>
          </div>
          <div className="services-w3ls">
             <div className="container" style={{ display: "flex" }}>



                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                        <b>Bestivert-8</b>
                        </h1>
                        <div className="products-li">
                        <div className="lightbox-container">
                            {bestivert8LightboxOpen && (
                            <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                            src={bestivert8}
                            style={{ maxWidth: 400, cursor: "pointer" }}
                            alt=""
                            onClick={openbestivert8Lightbox}
                            />
                        </div>
                        {bestivert8LightboxOpen && (
                            <div className="lightbox-content">
                            <img src={bestivert8} className="lightbox-image" alt="" />
                            </div>
                        )}
                        </div>
                    </div>
          


                    <div className="main-products" style={{ marginTop: 50 }}>
             <h1 style={{ textAlign: "center", marginBottom: 30 }}>
               <b>Bestivert-16</b>
             </h1>
             <div className="products-li">
               <div className="lightbox-container">
                 {bestivert16LightboxOpen && (
                   <div className="lightbox-overlay" onClick={closeLightboxes} />
                 )}
                 <img
                   src={bestivert16}
                   style={{ maxWidth: 400, cursor: "pointer" }}
                   alt=""
                   onClick={openbestivert16Lightbox}
                 />
               </div>
               {bestivert16LightboxOpen && (
                 <div className="lightbox-content">
                   <img src={bestivert16} className="lightbox-image" alt="" />
                 </div>
               )}
             </div>
           </div>





           <div className="main-products" style={{ marginTop: 50 }}>
           <h1 style={{ textAlign: "center", marginBottom: 30 }}>
             <b>Bestivert-24 SR</b>
           </h1>
           <div className="products-li">
             <div className="lightbox-container">
               {bestivert24LightboxOpen && (
                 <div className="lightbox-overlay" onClick={closeLightboxes} />
               )}
               <img
                 src={bestivert24}
                 style={{ maxWidth: 400, cursor: "pointer" }}
                 alt=""
                 onClick={openbestivert24Lightbox}
               />
             </div>
             {bestivert24LightboxOpen && (
               <div className="lightbox-content">
                 <img src={bestivert24} className="lightbox-image" alt="" />
               </div>
             )}
           </div>
         </div>












          
             </div>
          </div>




    </div>
    
    
  );
};
export default Ventigo;
