import React, { useState } from "react";
import duloxin20 from "./Product-Images/duloxin20.jpg";
import duloxin30 from "./Product-Images/duloxin30.jpg";




import "./Product.css";

const Pain = () => {
    const [duloxin20LightboxOpen, setduloxin20LightboxOpen] = useState(false);
    const [duloxin30LightboxOpen, setduloxin30LightboxOpen] =useState(false);
  



    const openduloxin20Lightbox = () => {
        setduloxin20LightboxOpen(true);
      };

    const openduloxin30Lightbox =() =>{
        setduloxin30LightboxOpen(true);
    };

   

      


    const closeLightboxes = () => {
        setduloxin20LightboxOpen(false);
        setduloxin30LightboxOpen(false);
      
     
    };











  return (
    <div className="site-wrapper">
          <div className="container-main">
            <h1>Pain-Management</h1>
          </div>
          <div className="services-w3ls">
             <div className="container" style={{ display: "flex" }}>



                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                        <b>Duloxin-20</b>
                        </h1>
                        <div className="products-li">
                        <div className="lightbox-container">
                            {duloxin20LightboxOpen && (
                            <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                            src={duloxin20}
                            style={{ maxWidth: 400, cursor: "pointer" }}
                            alt=""
                            onClick={openduloxin20Lightbox}
                            />
                        </div>
                        {duloxin20LightboxOpen && (
                            <div className="lightbox-content">
                            <img src={duloxin20} className="lightbox-image" alt="" />
                            </div>
                        )}
                        </div>
                    </div>
          


                        <div className="main-products" style={{ marginTop: 50 }}>
                            <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                            <b>Duloxin-30</b>
                            </h1>
                            <div className="products-li">
                            <div className="lightbox-container">
                                {duloxin30LightboxOpen && (
                                <div className="lightbox-overlay" onClick={closeLightboxes} />
                                )}
                                <img
                                src={duloxin30}
                                style={{ maxWidth: 400, cursor: "pointer" }}
                                alt=""
                                onClick={openduloxin30Lightbox}
                                />
                            </div>
                            {duloxin30LightboxOpen && (
                                <div className="lightbox-content">
                                <img src={duloxin30} className="lightbox-image" alt="" />
                                </div>
                            )}
                            </div>
                        </div>
             </div>
          </div>

{/*Benfosam*/}

          




    </div>
    
    
  );
};
export default Pain;
