import React, { useState } from "react";
import liosam10 from "./Product-Images/liosam10.jpg";
import liosam20 from "./Product-Images/liosam20.jpg";
import liosam30 from "./Product-Images/liosam30.jpg";
import "./Product.css";

const Antispastic = () => {
    const [liosam10LightboxOpen, setliosam10LightboxOpen] = useState(false);
    const [liosam20LightboxOpen, setliosam20LightboxOpen] =useState(false);
    const [liosam30LightboxOpen, setliosam30LightboxOpen] =useState(false);
    


    const openliosam10Lightbox = () => {
        setliosam10LightboxOpen(true);
      };

    const openliosam20Lightbox =() =>{
        setliosam20LightboxOpen(true);
    };

    const openliosam30Lightbox = () =>{
        setliosam30LightboxOpen(true);
    };


      


    const closeLightboxes = () => {
        setliosam10LightboxOpen(false);
        setliosam20LightboxOpen(false);
        setliosam30LightboxOpen(false);

    };











  return (
    <div className="site-wrapper">
          <div className="container-main">
            <h1>Anti-Spastic</h1>
          </div>
          <div className="services-w3ls">
             <div className="container" style={{ display: "flex" }}>



                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                        <b>Liosam-10</b>
                        </h1>
                        <div className="products-li">
                        <div className="lightbox-container">
                            {liosam10LightboxOpen && (
                            <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                            src={liosam10}
                            style={{ maxWidth: 400, cursor: "pointer" }}
                            alt=""
                            onClick={openliosam10Lightbox}
                            />
                        </div>
                        {liosam10LightboxOpen && (
                            <div className="lightbox-content">
                            <img src={liosam10} className="lightbox-image" alt="" />
                            </div>
                        )}
                        </div>
                    </div>
          


                    <div className="main-products" style={{ marginTop: 50 }}>
             <h1 style={{ textAlign: "center", marginBottom: 30 }}>
               <b>Liosam-20</b>
             </h1>
             <div className="products-li">
               <div className="lightbox-container">
                 {liosam20LightboxOpen && (
                   <div className="lightbox-overlay" onClick={closeLightboxes} />
                 )}
                 <img
                   src={liosam20}
                   style={{ maxWidth: 400, cursor: "pointer" }}
                   alt=""
                   onClick={openliosam20Lightbox}
                 />
               </div>
               {liosam20LightboxOpen && (
                 <div className="lightbox-content">
                   <img src={liosam20} className="lightbox-image" alt="" />
                 </div>
               )}
             </div>
           </div>





           <div className="main-products" style={{ marginTop: 50 }}>
           <h1 style={{ textAlign: "center", marginBottom: 30 }}>
             <b>Liosam-30</b>
           </h1>
           <div className="products-li">
             <div className="lightbox-container">
               {liosam30LightboxOpen && (
                 <div className="lightbox-overlay" onClick={closeLightboxes} />
               )}
               <img
                 src={liosam30}
                 style={{ maxWidth: 400, cursor: "pointer" }}
                 alt=""
                 onClick={openliosam30Lightbox}
               />
             </div>
             {liosam30LightboxOpen && (
               <div className="lightbox-content">
                 <img src={liosam30} className="lightbox-image" alt="" />
               </div>
             )}
           </div>
         </div>












          
             </div>
          </div>




    </div>
    
    
  );
};
export default Antispastic;
