import React, { useState } from "react";
import olasammd from "./Product-Images/olasammd.jpg";
import olasammd10 from "./Product-Images/olasammd10.jpg";
import risproj1 from "./Product-Images/risproj1.jpg";
import risproj2 from "./Product-Images/risproj2.jpg";
import risprojplus from "./Product-Images/risprojplus.jpg";
import prozapine25 from "./Product-Images/prozapine25.jpg";
import prozapine50 from "./Product-Images/prozapine50.jpg";
import prozapine100 from "./Product-Images/prozapine100.jpg";
import prozapine200 from "./Product-Images/prozapine200.jpg";
import amiproj50 from "./Product-Images/amiproj50.jpg";
import amiproj100 from "./Product-Images/amiproj100.jpg";
import amiproj200 from "./Product-Images/amiproj200.jpg";
import ziproj20 from "./Product-Images/ziproj20.jpg";
import ziproj40 from "./Product-Images/ziproj40.jpg";
import arisam5 from "./Product-Images/arisam5.jpg";
import arisam10 from "./Product-Images/arisam10.jpg";
import diproj250 from "./Product-Images/diproj250.jpg";
import diproj500 from "./Product-Images/diproj500.jpg";



import "./Product.css"; // Import the CSS file for Antidepressant component

const Antipsychotic = () => {
  // State for each product's lightbox
  const [olasammdLightboxOpen, setolasammdLightboxOpen] = useState(false);
  const [olasammd10LightboxOpen, setolasammd10LightboxOpen] = useState(false);
  const [risproj1LightboxOpen, setrisproj1LightboxOpen] =useState(false);
  const [risproj2LightboxOpen, setrisproj2LightboxOpen] =useState(false);
  const [risprojplusLightboxOpen, setrisprojplusLightboxOpen] =useState(false);
  const [prozapine25LightboxOpen, setprozapine25LightboxOpen] =useState(false);
  const [prozapine50LightboxOpen, setprozapine50LightboxOpen] =useState(false);
  const [prozapine100LightboxOpen, setprozapine100LightboxOpen] =useState(false);
  const [prozapine200LightboxOpen, setprozapine200LightboxOpen] =useState(false);
  const [amiproj50LightboxOpen, setamiproj50LightboxOpen] =useState(false);
  const [amiproj100LightboxOpen, setamiproj100LightboxOpen] =useState(false);
  const [amiproj200LightboxOpen, setamiproj200LightboxOpen] =useState(false);
  const [ziproj20LightboxOpen, setziproj20LightboxOpen] =useState(false);
  const [ziproj40LightboxOpen, setziproj40LightboxOpen] =useState(false);
  const [arisam5LightboxOpen, setarisam5LightboxOpen] =useState(false);
  const [arisam10LightboxOpen, setarisam10LightboxOpen] =useState(false);
  const [diproj250LightboxOpen, setdiproj250LightboxOpen] =useState(false);
  const [diproj500LightboxOpen, setdiproj500LightboxOpen] =useState(false);










  const openolasammdLightbox = () => {
    setolasammdLightboxOpen(true);
  };

  const openolasammd10Lightbox = () => {
    setolasammd10LightboxOpen(true);
  };



  const openrisproj1Lightbox =() => {
    setrisproj1LightboxOpen(true);
  }

  const openrisproj2Lightbox =() => {
    setrisproj2LightboxOpen(true);
  }

  const openrisprojplusLightbox = () =>{
    setrisprojplusLightboxOpen(true);
  }

 
  const openprozapine25Lightbox = () => {
    setprozapine25LightboxOpen(true);
  }

  const openprozapine50Lightbox = () =>{
    setprozapine50LightboxOpen(true);
  }

  const openprozapine100Lightbox = () => {
    setprozapine100LightboxOpen(true);
  }

  const openprozapine200Lightbox = () =>{
    setprozapine200LightboxOpen(true);
  }



  const openamiproj50Lightbox = () =>{
    setamiproj50LightboxOpen(true);
  }


  const openamiproj100Lightbox = () =>{
    setamiproj100LightboxOpen(true);
  }

  const openamiproj200Lightbox = () =>{
    setamiproj200LightboxOpen(true);
  }

  const openziproj20Lightbox = () => {
    setziproj20LightboxOpen(true);
  }

  const openziproj40Lightbox = () =>{
    setziproj40LightboxOpen(true);
  }


  const openarisam5Lightbox = () =>{
    setarisam5LightboxOpen(true);
  }

  const openarisam10Lightbox = () =>{
    setarisam10LightboxOpen(true);
  }

  const opendiproj250Lightbox = () =>{
    setdiproj250LightboxOpen(true);
  }
  const opendiproj500Lightbox = () =>{
    setdiproj500LightboxOpen(true);
  }




  const closeLightboxes = () => {
    setolasammdLightboxOpen(false);
    setolasammd10LightboxOpen(false);
    setrisproj1LightboxOpen(false);
    setrisproj2LightboxOpen(false);
    setrisprojplusLightboxOpen(false);
    setprozapine25LightboxOpen(false);
    setprozapine50LightboxOpen(false);
    setprozapine100LightboxOpen(false);
    setprozapine200LightboxOpen(false);
    setamiproj50LightboxOpen(false);
    setamiproj100LightboxOpen(false);
    setamiproj200LightboxOpen(false);
    setziproj20LightboxOpen(false);
    setziproj40LightboxOpen(false);
    setarisam5LightboxOpen(false);
    setarisam10LightboxOpen(false);
    setdiproj250LightboxOpen(false);
    setdiproj500LightboxOpen(false);

  };

  return (
    <div className="site-wrapper">
     <div className="container-main">
        <h1>Antipsychotic / Mood stabilizer</h1>
       
      </div>
      <div className="services-w3ls">
        <div className="container" style={{ display: "flex" }}>
          {/* Olasam-MD */}
          <div className="main-products" style={{ marginTop: 50 }}>
            <h1 style={{ textAlign: "center", marginBottom: 30 }}>
              <b>Olasam MD-1.25</b>
            </h1>
            <div className="products-li">
              <div className="lightbox-container">
                {olasammdLightboxOpen && (
                  <div className="lightbox-overlay" onClick={closeLightboxes} />
                )}
                <img
                  src={olasammd}
                  style={{ maxWidth: 400, cursor: "pointer" }}
                  alt=""
                  onClick={openolasammdLightbox}
                />
              </div>
              {olasammdLightboxOpen && (
                <div className="lightbox-content">
                  <img src={olasammd} className="lightbox-image" alt="" />
                </div>
              )}
            </div>
          </div>

          {/* Olasam-MD10 */}
          <div className="main-products" style={{ marginTop: 50 }}>
            <h1 style={{ textAlign: "center", marginBottom: 30 }}>
              <b>Olasam MD-10</b>
            </h1>
            <div className="products-li">
              <div className="lightbox-container">
                {olasammd10LightboxOpen && (
                  <div className="lightbox-overlay" onClick={closeLightboxes} />
                )}
                <img
                  src={olasammd10}
                  style={{ maxWidth: 400, cursor: "pointer" }}
                  alt=""
                  onClick={openolasammd10Lightbox}
                />
              </div>
              {olasammd10LightboxOpen && (
                <div className="lightbox-content">
                  <img src={olasammd10} className="lightbox-image" alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>








      {/* Risproj */}
              
{/*1*/}
      <div className="services-w3ls">
          <div className="container" style={{ display: "flex" }}>
                <div className="main-products" style={{ marginTop: 50 }}>
                  <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                    <b>Risproj-1</b>
                  </h1>
                  <div className="products-li">
                    <div className="lightbox-container">
                      {risproj1LightboxOpen && (
                        <div className="lightbox-overlay" onClick={closeLightboxes} />
                      )}
                      <img
                        src={risproj1}
                        style={{ maxWidth: 400, cursor: "pointer" }}
                        alt=""
                        onClick={openrisproj1Lightbox}
                      />
                    </div>
                    {risproj1LightboxOpen && (
                      <div className="lightbox-content">
                        <img src={risproj1} className="lightbox-image" alt="" />
                      </div>
                    )}
                  </div>
                </div>


{/*2*/}
                <div className="main-products" style={{ marginTop: 50 }}>
                <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                  <b>Risproj-2</b>
                </h1>
                <div className="products-li">
                  <div className="lightbox-container">
                    {risproj2LightboxOpen && (
                      <div className="lightbox-overlay" onClick={closeLightboxes} />
                    )}
                    <img
                      src={risproj2}
                      style={{ maxWidth: 400, cursor: "pointer" }}
                      alt=""
                      onClick={openrisproj2Lightbox}
                    />
                  </div>
                  {risproj2LightboxOpen && (
                    <div className="lightbox-content">
                      <img src={risproj2} className="lightbox-image" alt="" />
                    </div>
                  )}
                </div>
              </div>



{/*3*/}
              <div className="main-products" style={{ marginTop: 50 }}>
              <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                <b>Risproj-Plus</b>
              </h1>
              <div className="products-li">
                <div className="lightbox-container">
                  {risprojplusLightboxOpen && (
                    <div className="lightbox-overlay" onClick={closeLightboxes} />
                  )}
                  <img
                    src={risprojplus}
                    style={{ maxWidth: 400, cursor: "pointer" }}
                    alt=""
                    onClick={openrisprojplusLightbox}
                  />
                </div>
                {risprojplusLightboxOpen && (
                  <div className="lightbox-content">
                    <img src={risprojplus} className="lightbox-image" alt="" />
                  </div>
                )}
              </div>
            </div>
        </div>      
      </div>

{/*Prozapine*/}



                  <div className="services-w3ls">
                  <div className="container" style={{ display: "flex" }}>
                  
                          <div className="main-products" style={{ marginTop: 50 }}>
                          <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                            <b>Prozapine-25</b>
                          </h1>
                          <div className="products-li">
                            <div className="lightbox-container">
                              {prozapine25LightboxOpen && (
                                <div className="lightbox-overlay" onClick={closeLightboxes} />
                              )}
                              <img
                                src={prozapine25}
                                style={{ maxWidth: 400, cursor: "pointer" }}
                                alt=""
                                onClick={openprozapine25Lightbox}
                              />
                            </div>
                            {prozapine25LightboxOpen && (
                              <div className="lightbox-content">
                                <img src={prozapine25} className="lightbox-image" alt="" />
                              </div>
                            )}
                          </div>
                          </div>
                          


                          {/* Prozapine-50 */}
                              
                          <div className="main-products" style={{ marginTop: 50 }}>
                          <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                            <b>Prozapine-50</b>
                          </h1>
                          <div className="products-li">
                            <div className="lightbox-container">
                              {prozapine50LightboxOpen && (
                                <div className="lightbox-overlay" onClick={closeLightboxes} />
                              )}
                              <img
                                src={prozapine50}
                                style={{ maxWidth: 400, cursor: "pointer" }}
                                alt=""
                                onClick={openprozapine50Lightbox}
                              />
                            </div>
                            {prozapine50LightboxOpen && (
                              <div className="lightbox-content">
                                <img src={prozapine50} className="lightbox-image" alt="" />
                              </div>
                            )}
                          </div>
                          </div>





                              {/*Prozapine-100*/}

                          <div className="main-products" style={{ marginTop: 50 }}>
                          <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                            <b>Prozapine-100</b>
                          </h1>
                          <div className="products-li">
                            <div className="lightbox-container">
                              {prozapine100LightboxOpen && (
                                <div className="lightbox-overlay" onClick={closeLightboxes} />
                              )}
                              <img
                                src={prozapine100}
                                style={{ maxWidth: 400, cursor: "pointer" }}
                                alt=""
                                onClick={openprozapine100Lightbox}
                              />
                            </div>
                            {prozapine100LightboxOpen && (
                              <div className="lightbox-content">
                                <img src={prozapine100} className="lightbox-image" alt="" />
                              </div>
                            )}
                          </div>
                          </div>
                  </div>
                  </div>












{/*prozapine*/}

                  <div className="services-w3ls">
                  <div className="container" style={{ display: "flex" }}>
                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                          <b>Prozapine-200</b>
                        </h1>
                        <div className="products-li">
                          <div className="lightbox-container">
                            {prozapine200LightboxOpen && (
                              <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                              src={prozapine200}
                              style={{ maxWidth: 400, cursor: "pointer" }}
                              alt=""
                              onClick={openprozapine200Lightbox}
                            />
                          </div>
                          {prozapine200LightboxOpen && (
                            <div className="lightbox-content">
                              <img src={prozapine200} className="lightbox-image" alt="" />
                            </div>
                          )}
                        </div>
                        </div>



             {/*Amiproj*/}

                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                          <b>Amiproj-50</b>
                        </h1>
                        <div className="products-li">
                          <div className="lightbox-container">
                            {amiproj50LightboxOpen && (
                              <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                              src={amiproj50}
                              style={{ maxWidth: 400, cursor: "pointer" }}
                              alt=""
                              onClick={openamiproj50Lightbox}
                            />
                          </div>
                          {amiproj50LightboxOpen && (
                            <div className="lightbox-content">
                              <img src={amiproj50} className="lightbox-image" alt="" />
                            </div>
                          )}
                        </div>
                        </div>







                                                  



                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                          <b>Amiproj-100</b>
                        </h1>
                        <div className="products-li">
                          <div className="lightbox-container">
                            {amiproj100LightboxOpen && (
                              <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                              src={amiproj100}
                              style={{ maxWidth: 400, cursor: "pointer" }}
                              alt=""
                              onClick={openamiproj100Lightbox}
                            />
                          </div>
                          {amiproj100LightboxOpen && (
                            <div className="lightbox-content">
                              <img src={amiproj100} className="lightbox-image" alt="" />
                            </div>
                          )}
                        </div>
                        </div>





                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                          <b>Amiproj-200</b>
                        </h1>
                        <div className="products-li">
                          <div className="lightbox-container">
                            {amiproj200LightboxOpen && (
                              <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                              src={amiproj200}
                              style={{ maxWidth: 400, cursor: "pointer" }}
                              alt=""
                              onClick={openamiproj200Lightbox}
                            />
                          </div>
                          {amiproj200LightboxOpen && (
                            <div className="lightbox-content">
                              <img src={amiproj200} className="lightbox-image" alt="" />
                            </div>
                          )}
                        </div>
                        </div>

                  </div>
                  </div>




{/*Ziproj*/}
                  <div className="services-w3ls">
                  <div className="container" style={{ display: "flex" }}>

                  <div className="main-products" style={{ marginTop: 50 }}>
                  <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                    <b>Ziproj-20</b>
                  </h1>
                  <div className="products-li">
                    <div className="lightbox-container">
                      {ziproj20LightboxOpen && (
                        <div className="lightbox-overlay" onClick={closeLightboxes} />
                      )}
                      <img
                        src={ziproj20}
                        style={{ maxWidth: 400, cursor: "pointer" }}
                        alt=""
                        onClick={openziproj20Lightbox}
                      />
                    </div>
                    {ziproj20LightboxOpen && (
                      <div className="lightbox-content">
                        <img src={ziproj20} className="lightbox-image" alt="" />
                      </div>
                    )}
                  </div>
                  </div>




                  <div className="main-products" style={{ marginTop: 50 }}>
                  <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                    <b>Ziproj-40</b>
                  </h1>
                  <div className="products-li">
                    <div className="lightbox-container">
                      {ziproj40LightboxOpen && (
                        <div className="lightbox-overlay" onClick={closeLightboxes} />
                      )}
                      <img
                        src={ziproj40}
                        style={{ maxWidth: 400, cursor: "pointer" }}
                        alt=""
                        onClick={openziproj40Lightbox}
                      />
                    </div>
                    {ziproj40LightboxOpen && (
                      <div className="lightbox-content">
                        <img src={ziproj40} className="lightbox-image" alt="" />
                      </div>
                    )}
                  </div>
                  </div>
             </div>
                </div>

          

                      

                  
    {/*Arisam*/}

                <div className="services-w3ls">
                <div className="container" style={{ display: "flex" }}>

                <div className="main-products" style={{ marginTop: 50 }}>
                <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                  <b>Arisam-5</b>
                </h1>
                <div className="products-li">
                  <div className="lightbox-container">
                    {arisam5LightboxOpen && (
                      <div className="lightbox-overlay" onClick={closeLightboxes} />
                    )}
                    <img
                      src={arisam5}
                      style={{ maxWidth: 400, cursor: "pointer" }}
                      alt=""
                      onClick={openarisam5Lightbox}
                    />
                  </div>
                  {arisam5LightboxOpen && (
                    <div className="lightbox-content">
                      <img src={arisam5} className="lightbox-image" alt="" />
                    </div>
                  )}
                </div>
                </div>

                <div className="main-products" style={{ marginTop: 50 }}>
                <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                  <b>Arisam-10</b>
                </h1>
                <div className="products-li">
                  <div className="lightbox-container">
                    {arisam10LightboxOpen && (
                      <div className="lightbox-overlay" onClick={closeLightboxes} />
                    )}
                    <img
                      src={arisam10}
                      style={{ maxWidth: 400, cursor: "pointer" }}
                      alt=""
                      onClick={openarisam10Lightbox}
                    />
                  </div>
                  {arisam10LightboxOpen && (
                    <div className="lightbox-content">
                      <img src={arisam10} className="lightbox-image" alt="" />
                    </div>
                  )}
                </div>
                </div>
                </div>
                </div>


  {/*Diproj*/}





                     <div className="services-w3ls">
                        <div className="container" style={{ display: "flex" }}>
                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                          <b>Diproj-250</b>
                        </h1>
                        <div className="products-li">
                          <div className="lightbox-container">
                            {diproj250LightboxOpen && (
                              <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                              src={diproj250}
                              style={{ maxWidth: 400, cursor: "pointer" }}
                              alt=""
                              onClick={opendiproj250Lightbox}
                            />
                          </div>
                          {diproj250LightboxOpen && (
                            <div className="lightbox-content">
                              <img src={diproj250} className="lightbox-image" alt="" />
                            </div>
                          )}
                        </div>
                        </div>





                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                          <b>Diproj-500</b>
                        </h1>
                        <div className="products-li">
                          <div className="lightbox-container">
                            {diproj500LightboxOpen && (
                              <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                              src={diproj500}
                              style={{ maxWidth: 400, cursor: "pointer" }}
                              alt=""
                              onClick={opendiproj500Lightbox}
                            />
                          </div>
                          {diproj500LightboxOpen && (
                            <div className="lightbox-content">
                              <img src={diproj500} className="lightbox-image" alt="" />
                            </div>
                          )}
                        </div>
                        </div>
                        
                        
                        </div>
                      </div>








    </div>
  );
};

export default Antipsychotic;
