import React from "react";
import "../About/About.css";
import lb from "./images/lb.jpg";
import gb from "./images/gb.jpg";

const About = () => {
  return (
    <div>
      <div className="about-home">
        <div className="about-center">
          <h1>About Us</h1>
        </div>
      </div>

      <div className="main-abt-abt">
        <h1>Welcome to Samproj Pharma</h1>
        <p>
          Samproj is a new century dynamic company established in the year 2020
          in Pune. As a corporate, we believe in providing an innovative
          solution to healthcare problems and possess an entrepreneurial
          culture to build company and products for the same. We are committed
          to continuously upgrade our knowledge, expertise, and technology for
          the cause of health.
        </p>
      </div>

      <div className="container">
        <div className="main-cont">
          <div className="main-p">
            <p>
              <b>Vision: </b> Our vision is to become a global healthcare
              organization driven by people, research, and technology and to be
              recognized as a domestic and internationally integrated healthcare
              and pharmaceutical company through Innovation, Quality, Competence,
              and Economy.
            </p>
          </div>
          <div className="main-img">
            <img src={lb} alt="Mainimg" />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="main-cont">
          <div className="main-img">
            <img src={gb} alt="Mainimg" />
          </div>
          <div className="main-p">
            <p>
              <b>Mission: </b>At Samproj Pharmaceuticals, our mission is to enhance the well-being and quality of life for individuals worldwide by delivering innovative, high-quality, and cost-effective healthcare solutions. We are dedicated to leveraging the collective expertise of our talented team, cutting-edge research, and advanced technology to provide a comprehensive range of pharmaceutical products and services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
