import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div>
      
    <footer className="footer-distributed">

      <div className="footer-left">

        <h3>Sam<span>proj</span></h3>

        <p className="footer-links">
          <a href="/samproj" className="link-1">Home</a>
          
          <a href="/about">About</a>
        
          <a href="/productlist">Product</a>
        
          <a href="/contact">Contact</a>
          
          <a href="/gallery">Gallery</a>
          
       
        </p>

        <p className="footer-company-name">© Copyright Samproj. All Rights Reserved. @Designed by reflex Life</p>
      </div>

      <div className="footer-center">

        <div>
          <i className="fa fa-map-marker"></i>
          <p><span>101, Avalon, Suncity Road, </span> Sinhagad Road, Pune Maharashtra - 411051</p>
        </div>

        <div>
          <i className="fa fa-phone"></i>
          <p>+919890096181</p>
        </div>

        <div>
          <i className="fa fa-envelope"></i>
          <p><a href="mailto:support@company.com">info@samprojpharma.com</a></p>
        </div>

      </div>

      <div className="footer-right">

        <p className="footer-company-about">
          <span>About the company</span>
          Samproj is a new age dynamic company established in the year 2020 in Pune. As a corporate, we believe in providing an innovative solution to healthcare problems and possess an entrepreneurial culture to build company and products for the same.        </p>

        <div className="footer-icons">

          <a href="/"><i className="fa fa-facebook"></i></a>
          <a href="/"><i className="fa fa-twitter"></i></a>
          <a href="/"><i className="fa fa-linkedin"></i></a>
          <a href="/"><i className="fa fa-github"></i></a>

        </div>

      </div>

    </footer>
    </div>
  );
};

export default Footer;
