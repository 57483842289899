import React, { useState } from "react";
import cilasam from "./Product-Images/cilasam-plus.jpg";
import cilasamh from "./Product-Images/cilasam-H.jpg";
import cilasamls from "./Product-Images/cilasam-ls.jpg";
import Tryptosam10 from "./Product-Images/Tryptosam-10.jpg";
import Tryptosam25 from "./Product-Images/Tryptosam-25.jpg";
import Tryptosamcz from "./Product-Images/Tryptosam-cz.jpg";
import Tryptosamczh from "./Product-Images/Tryptosam-czh.jpg";
import venmax25 from "./Product-Images/Venmax25.jpg";
import venmax50 from "./Product-Images/venmax50.jpg";
import venmax100 from "./Product-Images/venmax100.jpg";
import paritin12 from "./Product-Images/paritin12.jpg";
import paritincr from "./Product-Images/paritincr.jpg";
import paritin25 from "./Product-Images/paritincr25.jpg";


import "./Product.css"; // Import the CSS file for Antidepressant component

const Antidepressant = () => {
  // State for each product's lightbox
  const [cilasamLightboxOpen, setCilasamLightboxOpen] = useState(false);
  const [cilasamhLightboxOpen, setCilasamhLightboxOpen] = useState(false);
  const [cilasamlsLightboxOpen, setCilasamlsLightboxOpen] = useState(false);
  const [Tryptosam10LightboxOpen, setTryptosam10LightboxOpen] =useState(false);
  const [Tryptosam25LightboxOpen, setTryptosam25LightboxOpen] =useState(false);
  const [TryptosamczLightboxOpen, setTryptosamczLightboxOpen] =useState(false);
  const [TryptosamczhLightboxOpen, setTryptosamczhLightboxOpen] =useState(false);
  const [venmax25LightboxOpen, setvenmax25LightboxOpen] =useState(false);
  const [venmax50LightboxOpen, setvenmax50LightboxOpen] =useState(false);
  const [venmax100LightboxOpen, setvenmax100LightboxOpen] =useState(false);
  const [paritin12LightboxOpen, setparitin12LightboxOpen] =useState(false);
  const [paritincrLightboxOpen, setparitincrLightboxOpen] =useState(false);
  const [paritin25LightboxOpen, setparitin25LightboxOpen] =useState(false);










  const openCilasamLightbox = () => {
    setCilasamLightboxOpen(true);
  };

  const openCilasamhLightbox = () => {
    setCilasamhLightboxOpen(true);
  };

  const openCilasamlsLightbox = () => {
    setCilasamlsLightboxOpen(true);
  };

  const openTryptosam10Lightbox =() => {
    setTryptosam10LightboxOpen(true);
  }

  const openTryptosam25Lightbox =() => {
    setTryptosam25LightboxOpen(true);
  }

  const openTryptosamczLightbox = () =>{
    setTryptosamczLightboxOpen(true);
  }

  const openTryptosamczhLightbox = () => {
    setTryptosamczhLightboxOpen(true);
  }

  const openvenmax25Lightbox = () => {
    setvenmax25LightboxOpen(true);
  }

  const openvenmax50Lightbox = () =>{
    setvenmax50LightboxOpen(true);
  }

  const openvenmax100Lightbox = () => {
    setvenmax100LightboxOpen(true);
  }

  const openparitin12Lightbox = () =>{
    setparitin12LightboxOpen(true);
  }



  const openparitincrLightbox = () =>{
    setparitincrLightboxOpen(true);
  }


  const openparitincr25Lightbox = () =>{
    setparitin25LightboxOpen(true);
  }






  const closeLightboxes = () => {
    setCilasamLightboxOpen(false);
    setCilasamhLightboxOpen(false);
    setCilasamlsLightboxOpen(false);
    setTryptosam10LightboxOpen(false);
    setTryptosam25LightboxOpen(false);
    setTryptosamczLightboxOpen(false);
    setTryptosamczhLightboxOpen(false);
    setvenmax25LightboxOpen(false);
    setvenmax50LightboxOpen(false);
    setvenmax100LightboxOpen(false);
    setparitin12LightboxOpen(false);
    setparitincrLightboxOpen(false);
    setparitin25LightboxOpen(false);

  };

  return (
    <div className="site-wrapper">
     <div className="container-main">
        <h1>Antidepressant</h1>
       
      </div>
      <div className="services-w3ls">
        <div className="container" style={{ display: "flex" }}>
          {/* Cilasam Plus */}
          <div className="main-products" style={{ marginTop: 50 }}>
            <h1 style={{ textAlign: "center", marginBottom: 30 }}>
              <b>Cilasam Plus</b>
            </h1>
            <div className="products-li">
              <div className="lightbox-container">
                {cilasamLightboxOpen && (
                  <div className="lightbox-overlay" onClick={closeLightboxes} />
                )}
                <img
                  src={cilasam}
                  style={{ maxWidth: 400, cursor: "pointer" }}
                  alt=""
                  onClick={openCilasamLightbox}
                />
              </div>
              {cilasamLightboxOpen && (
                <div className="lightbox-content">
                  <img src={cilasam} className="lightbox-image" alt="" />
                </div>
              )}
            </div>
          </div>

          {/* Cilasam-H */}
          <div className="main-products" style={{ marginTop: 50 }}>
            <h1 style={{ textAlign: "center", marginBottom: 30 }}>
              <b>Cilasam-H</b>
            </h1>
            <div className="products-li">
              <div className="lightbox-container">
                {cilasamhLightboxOpen && (
                  <div className="lightbox-overlay" onClick={closeLightboxes} />
                )}
                <img
                  src={cilasamh}
                  style={{ maxWidth: 400, cursor: "pointer" }}
                  alt=""
                  onClick={openCilasamhLightbox}
                />
              </div>
              {cilasamhLightboxOpen && (
                <div className="lightbox-content">
                  <img src={cilasamh} className="lightbox-image" alt="" />
                </div>
              )}
            </div>
          </div>

          {/* Cilasam-LS */}
          <div className="main-products" style={{ marginTop: 50 }}>
            <h1 style={{ textAlign: "center", marginBottom: 30 }}>
              <b>Cilasam-LS</b>
            </h1>
            <div className="products-li">
              <div className="lightbox-container">
                {cilasamlsLightboxOpen && (
                  <div className="lightbox-overlay" onClick={closeLightboxes} />
                )}
                <img
                  src={cilasamls}
                  style={{ maxWidth: 400, cursor: "pointer" }}
                  alt=""
                  onClick={openCilasamlsLightbox}
                />
              </div>
              {cilasamlsLightboxOpen && (
                <div className="lightbox-content">
                  <img src={cilasamls} className="lightbox-image" alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>








      {/* Tryptosam */}
              
{/*1*/}
      <div className="services-w3ls">
          <div className="container" style={{ display: "flex" }}>
                <div className="main-products" style={{ marginTop: 50 }}>
                  <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                    <b>Tryptosam-10</b>
                  </h1>
                  <div className="products-li">
                    <div className="lightbox-container">
                      {Tryptosam10LightboxOpen && (
                        <div className="lightbox-overlay" onClick={closeLightboxes} />
                      )}
                      <img
                        src={Tryptosam10}
                        style={{ maxWidth: 400, cursor: "pointer" }}
                        alt=""
                        onClick={openTryptosam10Lightbox}
                      />
                    </div>
                    {Tryptosam10LightboxOpen && (
                      <div className="lightbox-content">
                        <img src={Tryptosam10} className="lightbox-image" alt="" />
                      </div>
                    )}
                  </div>
                </div>


{/*2*/}
                <div className="main-products" style={{ marginTop: 50 }}>
                <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                  <b>Tryptosam-25</b>
                </h1>
                <div className="products-li">
                  <div className="lightbox-container">
                    {Tryptosam25LightboxOpen && (
                      <div className="lightbox-overlay" onClick={closeLightboxes} />
                    )}
                    <img
                      src={Tryptosam25}
                      style={{ maxWidth: 400, cursor: "pointer" }}
                      alt=""
                      onClick={openTryptosam25Lightbox}
                    />
                  </div>
                  {Tryptosam25LightboxOpen && (
                    <div className="lightbox-content">
                      <img src={Tryptosam25} className="lightbox-image" alt="" />
                    </div>
                  )}
                </div>
              </div>



{/*3*/}
              <div className="main-products" style={{ marginTop: 50 }}>
              <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                <b>Tryptosam-CZ</b>
              </h1>
              <div className="products-li">
                <div className="lightbox-container">
                  {TryptosamczLightboxOpen && (
                    <div className="lightbox-overlay" onClick={closeLightboxes} />
                  )}
                  <img
                    src={Tryptosamcz}
                    style={{ maxWidth: 400, cursor: "pointer" }}
                    alt=""
                    onClick={openTryptosamczLightbox}
                  />
                </div>
                {TryptosamczLightboxOpen && (
                  <div className="lightbox-content">
                    <img src={Tryptosamcz} className="lightbox-image" alt="" />
                  </div>
                )}
              </div>
            </div>



{/*4*/}


              <div className="main-products" style={{ marginTop: 50 }}>
              <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                <b>Tryptosam-CZH</b>
              </h1>
              <div className="products-li">
                <div className="lightbox-container">
                  {TryptosamczhLightboxOpen && (
                    <div className="lightbox-overlay" onClick={closeLightboxes} />
                  )}
                  <img
                    src={Tryptosamczh}
                    style={{ maxWidth: 400, cursor: "pointer" }}
                    alt=""
                    onClick={openTryptosamczhLightbox}
                  />
                </div>
                {TryptosamczhLightboxOpen && (
                  <div className="lightbox-content">
                    <img src={Tryptosamczh} className="lightbox-image" alt="" />
                  </div>
                )}
              </div>
              </div>

        </div>      
      </div>

{/*Venmax*/}



                  <div className="services-w3ls">
                  <div className="container" style={{ display: "flex" }}>
                  
                          <div className="main-products" style={{ marginTop: 50 }}>
                          <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                            <b>Venmax-25</b>
                          </h1>
                          <div className="products-li">
                            <div className="lightbox-container">
                              {venmax25LightboxOpen && (
                                <div className="lightbox-overlay" onClick={closeLightboxes} />
                              )}
                              <img
                                src={venmax25}
                                style={{ maxWidth: 400, cursor: "pointer" }}
                                alt=""
                                onClick={openvenmax25Lightbox}
                              />
                            </div>
                            {venmax25LightboxOpen && (
                              <div className="lightbox-content">
                                <img src={venmax25} className="lightbox-image" alt="" />
                              </div>
                            )}
                          </div>
                          </div>
                          


                          {/* Venmax-50 */}
                              
                          <div className="main-products" style={{ marginTop: 50 }}>
                          <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                            <b>Venmax-50</b>
                          </h1>
                          <div className="products-li">
                            <div className="lightbox-container">
                              {venmax50LightboxOpen && (
                                <div className="lightbox-overlay" onClick={closeLightboxes} />
                              )}
                              <img
                                src={venmax50}
                                style={{ maxWidth: 400, cursor: "pointer" }}
                                alt=""
                                onClick={openvenmax50Lightbox}
                              />
                            </div>
                            {venmax50LightboxOpen && (
                              <div className="lightbox-content">
                                <img src={venmax50} className="lightbox-image" alt="" />
                              </div>
                            )}
                          </div>
                          </div>







                          <div className="main-products" style={{ marginTop: 50 }}>
                          <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                            <b>Venmax-100</b>
                          </h1>
                          <div className="products-li">
                            <div className="lightbox-container">
                              {venmax100LightboxOpen && (
                                <div className="lightbox-overlay" onClick={closeLightboxes} />
                              )}
                              <img
                                src={venmax100}
                                style={{ maxWidth: 400, cursor: "pointer" }}
                                alt=""
                                onClick={openvenmax100Lightbox}
                              />
                            </div>
                            {venmax100LightboxOpen && (
                              <div className="lightbox-content">
                                <img src={venmax100} className="lightbox-image" alt="" />
                              </div>
                            )}
                          </div>
                          </div>
                  </div>
                  </div>












{/*Paritin*/}

                  <div className="services-w3ls">
                  <div className="container" style={{ display: "flex" }}>
                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                          <b>Paritin Plus 12.5</b>
                        </h1>
                        <div className="products-li">
                          <div className="lightbox-container">
                            {paritin12LightboxOpen && (
                              <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                              src={paritin12}
                              style={{ maxWidth: 400, cursor: "pointer" }}
                              alt=""
                              onClick={openparitin12Lightbox}
                            />
                          </div>
                          {paritin12LightboxOpen && (
                            <div className="lightbox-content">
                              <img src={paritin12} className="lightbox-image" alt="" />
                            </div>
                          )}
                        </div>
                        </div>




                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                          <b>Paritin-CR 12.5</b>
                        </h1>
                        <div className="products-li">
                          <div className="lightbox-container">
                            {paritincrLightboxOpen && (
                              <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                              src={paritincr}
                              style={{ maxWidth: 400, cursor: "pointer" }}
                              alt=""
                              onClick={openparitincrLightbox}
                            />
                          </div>
                          {paritincrLightboxOpen && (
                            <div className="lightbox-content">
                              <img src={paritincr} className="lightbox-image" alt="" />
                            </div>
                          )}
                        </div>
                        </div>







                                                  



                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                          <b>Paritin-CR 25</b>
                        </h1>
                        <div className="products-li">
                          <div className="lightbox-container">
                            {paritin25LightboxOpen && (
                              <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                              src={paritin25}
                              style={{ maxWidth: 400, cursor: "pointer" }}
                              alt=""
                              onClick={openparitincr25Lightbox}
                            />
                          </div>
                          {paritin25LightboxOpen && (
                            <div className="lightbox-content">
                              <img src={paritin25} className="lightbox-image" alt="" />
                            </div>
                          )}
                        </div>
                        </div>

                  </div>
                  </div>






          
    




  














    </div>
  );
};

export default Antidepressant;
