import React, { useState } from "react";
import rozaproja from "./Product-Images/rozaproja.jpg";
import rozaprojg from "./Product-Images/rozaprojg.jpg";

import "./Product.css";

const Cvseg = () => {
    const [rozaprojaLightboxOpen, setrozaprojaLightboxOpen] = useState(false);
    const [rozaprojgLightboxOpen, setrozaprojgLightboxOpen] =useState(false);

    


    const openrozaprojaLightbox = () => {
        setrozaprojaLightboxOpen(true);
      };

    const openrozaprojgLightbox =() =>{
        setrozaprojgLightboxOpen(true);
    };

   


      


    const closeLightboxes = () => {
        setrozaprojaLightboxOpen(false);
        setrozaprojgLightboxOpen(false);
       

    };











  return (
    <div className="site-wrapper">
          <div className="container-main">
            <h1>CV-Segment</h1>
          </div>
          <div className="services-w3ls">
             <div className="container" style={{ display: "flex" }}>



                        <div className="main-products" style={{ marginTop: 50 }}>
                        <h1 style={{ textAlign: "center", marginBottom: 30 }}>
                        <b>Rozaproj-A</b>
                        </h1>
                        <div className="products-li">
                        <div className="lightbox-container">
                            {rozaprojaLightboxOpen && (
                            <div className="lightbox-overlay" onClick={closeLightboxes} />
                            )}
                            <img
                            src={rozaproja}
                            style={{ maxWidth: 400, cursor: "pointer" }}
                            alt=""
                            onClick={openrozaprojaLightbox}
                            />
                        </div>
                        {rozaprojaLightboxOpen && (
                            <div className="lightbox-content">
                            <img src={rozaproja} className="lightbox-image" alt="" />
                            </div>
                        )}
                        </div>
                    </div>
          


                    <div className="main-products" style={{ marginTop: 50 }}>
             <h1 style={{ textAlign: "center", marginBottom: 30 }}>
               <b>Rozaproj-Gold</b>
             </h1>
             <div className="products-li">
               <div className="lightbox-container">
                 {rozaprojgLightboxOpen && (
                   <div className="lightbox-overlay" onClick={closeLightboxes} />
                 )}
                 <img
                   src={rozaprojg}
                   style={{ maxWidth: 400, cursor: "pointer" }}
                   alt=""
                   onClick={openrozaprojgLightbox}
                 />
               </div>
               {rozaprojgLightboxOpen && (
                 <div className="lightbox-content">
                   <img src={rozaprojg} className="lightbox-image" alt="" />
                 </div>
               )}
             </div>
           </div>





        












          
             </div>
          </div>




    </div>
    
    
  );
};
export default Cvseg;
